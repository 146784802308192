<template>
  <div style="display: flex; align-items: center; width: 100%">
    <div style="color: #014caa; font-weight: 700">
      <el-tooltip
        class="item"
        effect="dark"
        :content="all_file_type[item.fileTypeId].name"
        placement="top"
      >
        <div style="width: 30px; border-radius: 5px 0 0 5px; padding: 6px 14px">
          <div v-if="all_file_type[item.fileTypeId].icon == -1">
            {{ all_file_type[item.fileTypeId].name.slice(0, 1) }}
          </div>
          <i
            v-else
            :class="
              'iconfont icon-a-' +
              all_file_type[item.fileTypeId].icon.slice(0, 1)
            "
          ></i>
        </div>
      </el-tooltip>
    </div>
    <el-tooltip
      v-if="file"
      v-ak-tooltip-auto-show
      effect="dark"
      placement="top"
      :content="item.fileName"
    >
      <div
        style="
          margin-left: 10px;
          width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        "
        v-html="highlightText(showToptitle(item.fileKey + ':' + item.fileName))"
      ></div>
    </el-tooltip>
    <el-tooltip
      v-else
      v-ak-tooltip-auto-show
      effect="dark"
      placement="top"
      :content="item.topic"
    >
      <div
        style="
          margin-left: 10px;
          width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        "
        v-html="highlightText(showToptitle(item.nodeKey + ':' + item.topic))"
      ></div>
    </el-tooltip>
    <!-- <div v-else style="margin-left: 10px">
      {{
        `${item.nodeKey}: ${
          item.topic.length > 11 ? item.topic.slice(0, 10) + "..." : item.topic
        }`
      }}
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
    },
    file: {
      type: Boolean,
      default: false,
    },
    keyWord: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["all_file_type"]),
  },
  methods: {
    highlightText(text) {
      const targetString = this.keyWord; // 要高亮显示的目标字符串
      const regex = new RegExp(targetString, "g");
      const highlightedText = text.replace(
        regex,
        `<mark style="background-color: rgba(42, 130, 228, 0.13)">${targetString}</mark>`
      );
      return highlightedText;
    },
    showToptitle(title) {
      return title.replace(/\n/g, "");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
